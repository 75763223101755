/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.co-orange {
  color: #f24822;
}
.co-white {
  color: white;
}

.orangeTextClash {
  font-size: 28px;
}
.orangeTextClash {
  font-size: 32px;
  font-weight: 700;
  text-transform: capitalize;
  color: #f24822;
  font-family: var(--font_family_clash_display);
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.textAccordin, .textStake {
  font-size: 20px;
  letter-spacing: -.4px;
}
.textStake {
  font-weight: 400;
  text-transform: capitalize;
  line-height: 30px;
}


.backgroundOrangeLinear {
  background: linear-gradient(231deg, #f9a52e 10.79%, #ff6400 132.55%);
}
.rounded-\[30px\] {
  border-radius: 30px;
}
.overflow-x-auto {
  overflow-x: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (min-width: 1024px){
.poppineText {
  font-size: 28px;
}
}

@media (min-width: 768px)
{

.poppineText {
  font-size: 22px;
}
}
.poppineText {
  font-weight: 400;
  line-height: 151%;
  letter-spacing: -.56px;
  font-size: 18px;
}

.grid {
  display: grid;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.mt-2 {
  margin-top: .5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.borderAccordin {
  border-radius: 10px;
  border-width: 1.5px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(158 176 203 / var(--tw-border-opacity));
}

.textAccordin {
  font-weight: 600;
  line-height: 28.65px;
}
.textAccordin, .textStake {
  font-size: 20px;
  letter-spacing: -.4px;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
@media (min-width: 1024px){
.poppineText {
  font-size: 28px;
}
}
@media (min-width: 768px){
.poppineText {
  font-size: 22px;
}
}
.poppineText {
  font-weight: 400;
  line-height: 151%;
  letter-spacing: -.56px;
  font-size: 18px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.pr-\[32px\] {
  padding-right: 32px;
}
.pl-\[32px\] {
  padding-left: 32px;
}
.pb-\[5px\] {
  padding-bottom: 5px;
}
.cardBuyPika {
  width: 100%;
  height: auto;
  border-radius: 30px;
  background: #fff;
  box-shadow: 10px 10px 50px 0 rgba(0,0,0,.1);
}

.hover\:bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.btn:hover {
  background: linear-gradient(to right #ff6400, #f9a52e);
}
.p-2 {
  padding: .5rem;
}
.to-\[\#F9A52E\] {
  --tw-gradient-to: #f9a52e var(--tw-gradient-to-position);
}
.from-\[\#FF6400\] {
  --tw-gradient-from: #ff6400 var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(255, 100, 0, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.btnConnectWallet {
  color: white;
  text-align: center;
  text-shadow: 0 5px 50px rgba(0,0,0,.12);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -.48px;
  border-radius: 10px;
  /* background: black;
   */
   /* background-color: ; */
}
[role=button], button {
  cursor: pointer;
}

.btnConnectWallet:hover {
  color: white;
  /* background: #ff6400; */
  background-color: black;
}
.bg-custom-color {
  background-color: #c0ccdd;
}

.space-y-4>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem* calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem* var(--tw-space-y-reverse));
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.btn, .btnSm {
  color: white;
  text-align: center;
  text-shadow: 0 5px 50px rgba(0,0,0,.12);
  letter-spacing: -.48px;
  border-radius: 10px;
  /* background: linear-gradient(236deg, #f9a52e -35.87%, #ff6400 145.09%); */
  font-size: 24px;
  font-weight: 500;
}
.btn-organge {
  background: linear-gradient(236deg, #f9a52e -35.87%, #ff6400 145.09%);
}
.bg-white {
  background-color: white;
}
w3m-button {
  text-align: center;
}
.rounded-custom {
  border-radius: 50px;
  width: 90%;
}
.rounded-none {
  border-radius: 0px;
}
.bg-none{
  background: transparent;
}

.text-black {
 color: black !important;
}
.co-gray {
  color: #9eb0cb;
}

.font-normal {
  font-weight: 400;
}
.text-\[16px\] {
  font-size: 16px;
}

.text-\[24px\] {
  font-size: 24px;
}

.pt-10 {
  padding-top: 2.5rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.blackTextClash, .blackTextPoppin {
  font-weight: 600;
  color: black;
}

.customTabs {
  font-weight: 400;
  line-height: 143%;
  color: black;
}

.customTabs, .unList {
  font-size: 20px;
  letter-spacing: -.4px;
}

:where(.css-1b0bdye).ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  display: flex;
}
:where(.css-1b0bdye).ant-tabs-top, :where(.css-1b0bdye).ant-tabs-bottom {
  flex-direction: column;
}
.menuitem {
  padding:10px;
  margin-right:10px;
  background-color: white;
  font-size: 28px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: var(--font_family_clash_display);
  cursor: pointer;
}
.menuitem .active {
  color: #f24822;
 
}
.bg-cover {
  /* background-size: cover !important; */
}
.bg-repeat {
  background-repeat: repeat;
}

.bg-contain {
  background-size: contain;
}

.menu-active:checked+.mobile-header ul.mobile-menu-list {
  max-height: 400px;
  opacity: 1;
  margin-top: 40px;
  padding-bottom: 20px;
}

.game-box, .mobile-header, .mobile-header ul.mobile-menu-list {
  transition: all .5s ease 0s;
}
.ease-out {
  transition-timing-function: cubic-bezier(0,0,.2,1);
}
.opacity-0 {
  opacity: 0;
}

.menu-active:checked+.mobile-header  .menu-mobile1 {
  display: none;
}
.menu-active+.mobile-header  .menu-mobile1 {
  display: block;
}
.menu-active:checked+.mobile-header  .menu-mobile2 {
  display: block;
}
.menu-active+.mobile-header  .menu-mobile2 {
  display: none;
}



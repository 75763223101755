body {
  margin: 0;
  font-family: "Livvic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #252D42; */
}

a {
  color: white ;
}

a:-webkit-any-link {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input {
  text-align: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.max-w-\[1106px\] {
  max-width: 1106px;
}
.w-full {
  width: 100%;
}
.mt-\[-50px\] {
  margin-top: -50px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
* {
  padding: 0;
  box-sizing: border-box;
}
*, body, html {
  /* font-family: var(--font_family_proppins); */
  margin: 0;
}


.co-yellow {
  color: #f9d965 !important;
}

.marquee section {
  display: block;
  width: 200%;
  position: absolute;
  overflow: hidden;
  animation: marquee 40s linear infinite;
}
#marqueetext > span {
  font-family: var(--font_family_enigma1);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
}
.marquee div {
  float: left;
  width: 50%;
}
.marquee * {
  font-family: var(--font_family_enigma1);
}
.inner-font-inherit * {
  font-family: inherit;
}
@keyframes marquee {
  0% {
    left: 0;
  }
  to {
    left: -100%;
  }
}
.topNavabr {
  height: 50px;
}
.topNavabr > img {
  margin-left: 12px;
  margin-right: 20px;
}
.transtionHoverLogo {
  transition: transform 0.3s ease-in-out;
}
.transtionHover {
  transition: transform 0.1s ease-in-out;
}
.logo-postion {
  position: relative;
  top: 15px;
}
.navbarHome {
  color: var(--color-black);
  width: 100%;
}
.background-soarcrow {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

/* .custom-bg-hover {
  color: black;
  background-color: white;
} */
.custom-bg-hover, .custom-bg-hover svg path {
  transition: all .5s ease 0s;
  
}
.custom-bg-hover span {
  color: black;
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.custom-hero-section {
  background-size: cover;
  background-position: bottom;
}


.flex-container {
  display: flex;
}
.fontClash {
  font-family: var(--font_family_clash_display);
}
.fontEnigma {
  font-family: EnigmaBold, sans-serif;
}
.bgSkyVideo {
  height: 400px;
}
.bgSky,
.bgSkyVideo {
  background: linear-gradient(
    0deg,
    rgba(204, 229, 250, 0.01),
    rgba(204, 229, 250, 0.76) 47.4%,
    rgba(204, 229, 250, 0.07)
  );
}
.btnConnectWallet:hover {
  color: var(--color-white);
  /* background: var(--color-orange); */
  background-color: black;
}
.cloud-1 {
  animation: cloud1 4s infinite;
}
.cloud-3 {
  animation: cloud1 8s infinite;
}
@keyframes cloud1 {
  0% {
    transform: translatex(0);
  }
  50% {
    transform: translatex(-80px);
  }
}
.cloud-2 {
  animation: cloud2 8s infinite;
}
@keyframes cloud2 {
  0% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-40px);
  }
}
.cloud-4 {
  animation: cloud3 8s infinite;
}
@keyframes cloud3 {
  0% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-80px);
  }
}
.bg-custom-color {
  background-color: #c0ccdd;
}
.text-claim {
  font-size: 24px;
  color: #f24822;
}
.text-pika {
  font-size: 18px;
  color: #f24822;
}
.text-pika1 {
  font-size: 14px;
  color: #f24822;
}
.text-price {
  color: #9eb0cb;
  font-size: 22px;
}
.text-days {
  font-size: 12px;
  color: #9eb0cb;
}
.rounded {
  border-radius: 12px;
}
.border-custom-color {
  border: 2px solid #9eb0cb;
}
p strong {
  color: #000;
}
.text-green {
  color: #26d153;
}
.claim-now {
  color: #fff;
  background: #26d153;
  font-size: 14px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.inset-y-4 {
  top: 101px;
  bottom: 0;
}
.btngreen {
  background: #26d153;
  border: 2px solid #26d153;
  width: 48%;
  height: 50px;
}
.mt-30 {
  margin-top: 30px;
}
.social-icons ul {
  margin-left: unset;
}
.rounded-md {
  border-radius: 8px;
}
.game-fi {
  font-size: 20px;
  font-weight: 400;
  padding-left: 15px;
}
.gap-\[0px\] {
  gap: 20px;
}
.steps-list {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  list-style-type: inherit;
  padding-left: 25px;
}
.steps-list li {
  margin-bottom: 30px;
}
.text-red {
  color: #f24822;
}
.mt-50 {
  margin-top: 50px;
}
.btn-transparent {
  width: 48%;
  height: 50px;
  background: transparent;
  color: #000;
  border: 2px solid #000;
}
.btn-transparent:hover {
  /* background: #26d153; */
  background-color: black;
  border: 2px solid #26d153;
  color: #fff;
}
.btn:hover {
  background: linear-gradient(to right #ff6400, #f9a52e);
}


.gap-x-10 {
  -moz-column-gap: 2.5rem;
  column-gap: 2.5rem;
}
.iconSvgFirstRow {
  width: 150px;
  height: 57px;
}
.iconSvg, .iconSvgFirstRow {
  display: inline-block;
  background: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 767px){
  .iconSvgFirstRow {
      width: 103px;
      height: 40px;
  }
  }

  .gap-x-\[29px\] {
    -moz-column-gap: 29px;
    column-gap: 29px;
}

.iconSvgSecondRow, .iconSvgThirdRow {
  display: inline-block;
  width: 140px;
  background: no-repeat;
  background-size: contain;
}
.iconSvgSecondRow {
  height: 35px;
}

.iconSvgThirdRow {
  height: 40px;
}
.iconSvgSecondRow, .iconSvgThirdRow {
  display: inline-block;
  width: 140px;
  background: no-repeat;
  background-size: contain;
}
.iconSvgForthRow {
  display: inline-block;
  width: 70px;
  height: 70px;
  background: no-repeat;
  background-size: contain;
}
.iconSvgForthRow {
  display: inline-block;
  width: 70px;
  height: 70px;
  background: no-repeat;
  background-size: contain;
}

.audd {
  width: 150px;
  height: 150px;
  background-position: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/aud.afeaa3b3.svg");
}

.audd:hover {
  /* background-image: url("../public/images/audC.3bbdcfdf.svg"); */
  background-image: url("../public/images/audC.3bbdcfdf.svg");  
  cursor: pointer
}



.Kervru {
  background-image: url(../public/images/Kv.34137e83.svg) !important;
}

.Kervru:hover {
  background-image: url(../public/images/KvColor.491c22b4.svg) !important;
}

.bloomberg {
  background-image: url(../public/images/Bloomberg.2020cae5.svg) !important;
  height: 30px;
}

.bloomberg:hover {
  background-image: url(../public/images/BloombergColor.c03c9294.svg) !important;
  height: 30px;
}

.yahoo {
  background-image: url(../public/images/yahoo.b268717f.svg) !important;
}

.yahoo:hover {
  background-image: url(../public/images/yahooColor.a3ef4b66.svg) !important;
}

.outlook {
  background-image: url(../public/images/outlook.536049a8.svg) !important;
  height: 24px
}

.outlook:hover {
  background-image: url(../public/images/outlookColor.c93c4c85.svg) !important;
  height: 24px
}

.space {
  background-image: url(../public/images/space.7bc63e1b.svg) !important;
  height: 30px
}

.space:hover {
  background-image: url(../public/images/spaceColor.807f7531.svg) !important;
  height: 30px
}

.blocmail {
  background-image: url(../public/images/blocmail.f9463919.svg) !important;
}

.blocmail:hover {
  background-image: url(../public/images/blocmailColor.d2715b1f.svg) !important;
}

.eth {
  background-image: url(../public/images/eth.edea3016.svg) !important;
}

.eth:hover {
  background-image: url(../public/images/ethColor.62c75e26.svg) !important;
}

.cnews {
  background-image: url(../public/images/cnewsG.b65fc484.svg) !important;
  height: 32px
}

.cnews:hover {
  background-image: url(../public/images/cnewsC.8b030101.svg) !important;
  height: 32px
}

.crypto {
  background-image: url(../public/images/cryptoG.a54a8bb7.svg) !important;
  height: 20px
}

.crypto:hover {
  background-image: url(../public/images/cryptoC.39b2964b.svg) !important;
  height: 20px
}

.newsBTC {
  background-image: url(../public/images/newsBTCg.d7a5dd25.svg) !important;
}

.newsBTC:hover {
  background-image: url(../public/images/newsBTCC.1c311696.svg) !important;
}

.analytics {
  background-image: url(../public/images/analyticsG.beb94350.svg) !important;
  height: 18px
}

.analytics:hover {
  background-image: url(../public/images/analyticsC.8172ccb9.svg) !important;
  height: 18px
}

.cnewsFlash {
  background-image: url(../public/images/cnewsFlashG.0d446503.svg) !important;
  width: 140px;
  height: 57px
}

.cnewsFlash:hover {
  background-image: url(../public/images/cnewsFlashC.e2a7e238.svg) !important;
  width: 140px;
  height: 57px
}

.coin {
  background-image: url(../public/images/coinG.a82bfffb.svg) !important;
}

.coin:hover {
  background-image: url(../public/images/coinC.c03f83bb.svg) !important;
}

.tc {
  background-image: url(../public/images/TCG.d06d6f68.svg) !important;
}

.tc,.tc:hover {
  width: 47px;
  height: 47px
}

.tc:hover {
  background-image: url(../public/images/TCC.1af0c450.svg) !important;
}

.bitget {
  background-image: url(../public/images/bitget.9ab1a322.svg) !important;
  width: 140px;
  height: 37px
}

.bitget:hover {
  background-image: url(../public/images/bitgetC.0cd59c1e.svg) !important;
}

.unis {
  background-image: url(../public/images/unis.d67ae72e.svg) !important;
  width: 140px;
  height: 33px
}

.unis:hover {
  background-image: url(../public/images/unisC.052ebd3e.svg) !important;
}

.mexc {
  background-image: url(../public/images/mexc.c237419b.svg) !important;
  width: 140px;
  height: 20px
}

.mexc:hover {
  background-image: url(../public/images/mexcC.f266aeb0.svg) !important;
}

#sliderStakePage .ant-slider .ant-slider-track {
  background-color: orange!important
}

#sliderStakePage .ant-slider .ant-slider-handle:before {
  content: "";
  top: 20px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 111%
}

.soldLeft {
  font-weight: 600
}

.soldLeft,.soldRight {
  color: #000;
  font-size: 18px;
  line-height: 111%;
  letter-spacing: -.36px;
  text-transform: uppercase
}

.soldRight {
  font-weight: 400
}

.newsBtnActive {
  height: 50px;
  border-radius: 10px;
  border: 1.5px solid #000;
  color: #f24822;
  font-size: 28px;
  font-weight: 700
}


.newsBtn {
  height: 60px;
  border-radius: 10px;
  color: #9eb0cb;
  font-size: 24px;
  font-weight: 400
}

.swiper {
  height: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto
}

.swiper-slide {
  background-size: cover
}

.mySwiper2 {
  height: auto;
  width: 100%;
  border-radius: 30px;
  margin: 10px
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  border-radius: 10px
}

.mySwiper .swiper-slide {
  width: 230px;
  opacity: .7
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1
}

.swiper-slide img {
  display: block
}

.sliderCardText1 {
  color: #fff;
  text-align: right;
  font-size: 20px;
  letter-spacing: -.4px
}

.sliderCardText1,.textCard2 {
  font-style: normal;
  font-weight: 400;
  line-height: 143.25%;
  text-transform: capitalize
}

.textCard2 {
  color: #000;
  font-size: 24px;
  letter-spacing: -.48px
}
.a-z-30 {
  z-index: 30 !important;
}

.animation-box-group .game-box:first-child {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.min-w-\[242px\] {
  min-width: 242px;
}
.top-\[-1px\] {
  top: -1px;
}
.relative {
  position: relative;
}
.game-box, .mobile-header, .mobile-header ul.mobile-menu-list {
  transition: all .5s ease 0s;
}

@keyframes floating {
  0% {
      transform: translate(0)
  }

  50% {
      transform: translateY(15px)
  }

  to {
      transform: translate(0)
  }
}

.animation-box-group .game-box:nth-child(2) {
  animation-name: floating3;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.hover\:top-\[-10px\]:hover {
  top: -10px;
}
.min-w-\[242px\] {
  min-width: 242px;
}

@keyframes floating3 {
  0% {
      transform: translate(0)
  }

  50% {
      transform: translateY(-5px)
  }

  to {
      transform: translate(0)
  }
}

.animation-box-group .game-box:last-child {
  animation-name: floating2;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating2 {
  0% {
      transform: translate(0)
  }

  50% {
      transform: translateY(-15px)
  }

  to {
      transform: translate(0)
  }
}

.animation-box-group .game-box:nth-child(2) {
  animation-name: floating3;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out
}
.sticky {
  position: sticky;
}
.game-box, .mobile-header, .mobile-header ul.mobile-menu-list {
  transition: all .5s ease 0s;
}

.blackTextClash, .blackTextPoppin {
  font-weight: 600;
  color: var(--color-black);
}
.blackTextClash {
  font-size: 32px;
  text-transform: capitalize;
  font-family: var(--font_family_clash_display);
}
.steps-list {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  list-style-type: inherit;
  padding-left: 25px;
}
.btn-green {
  background-color: #26d153;
}
.btn-red {
  background-color: red;
}
.btn-orange {
  background: linear-gradient(236deg, #f9a52e -35.87%, #ff6400 145.09%) !important;
}









